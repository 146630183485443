<template>
  <div class="container mescroll-touch" id="toTop">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
          <img class="bannerImg" :src="item.File_Url" />
        </van-swipe-item>
      </van-swipe>
      <img class="goBack" src="@/assets/images/back_comm_icon@2x.png" @click="$router.go(-1)" />
    </div>
    <div class="content">
      <div class="title">
        <img :src="zone == 'xwzq_zgs' ? require('@/assets/images/China.png') : require('@/assets/images/Asia-Pacific.png')" />
        <div class="Asia_Pacific" @click="changeZone('xwzq_yts', '亚太区')"></div>
        <div class="China" @click="changeZone('xwzq_zgs', '中国区')"></div>
      </div>
      <div class="classifyBox" :class="{'classifyFlexBox': codeList.length > 3}">
        <span :class="{'active': activeTCode == item.TCode}" v-for="(item, index) in codeList" :key="index" @click="changeCode(item.Code, item.TCode, item.Name)">{{item.Name}}</span>
      </div>
      <div class="poster" v-show="activeTCode == 'xinwaizq|xwzq_zgs|xwzq_zgs_szgz|'">
        <img :src="poster">
      </div>
      <div class="listBox" id="dataList" :key="activeTCode" v-show="activeTCode != 'xinwaizq|xwzq_zgs|xwzq_zgs_szgz|'">
        <div class="lxlcContent" v-if="activeTCode == 'xinwaizq|xwzq_zgs|xwzq_zgs_lxlc|'">
          <div class="lxlcItem" @click="$router.push('/myWorks')">
            <span>我的作品</span>
            <img src="@/assets/images/rightArrow.png">
          </div>
          <div class="lxlcItem" @click="$router.push('/uploadWorks')">
            <span>上传练习作品</span>
            <img src="@/assets/images/rightArrow.png">
          </div>
        </div>
        <div class="workTitle" v-if="activeTCode == 'xinwaizq|xwzq_zgs|xwzq_zgs_lxlc|'">选手风采秀</div>
        <waterfall :col="col" :width="activeColumn != 'dkjy' && activeColumn != null ? itemWidth : null" :gutterWidth="activeColumn != 'dkjy' && activeColumn != null ? gutterWidth : null" :data="list">
          <template v-if="activeColumn == 'dkjy'">
            <div class="list list1"  v-for="item in list" :key="item.Id" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img1">
                <img class="item_cover1" :src="item.Thumbnail" />
                <img class="playIcon1" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content item_content1">
                <div>
                  <span class="item_title1">{{item.Title}}</span>
                  <p class="item_time">{{item.yyyymmdd}}</p>
                </div>
                <div class="item_nums">
                    <div class="nums">
                      <img src="@/assets/images/browse_comm_icon@2x.png" />
                      <span>{{item.WatchCount}}</span>
                    </div>
                    <div class="nums">
                      <img src="@/assets/images/like_comm_icon@2x.png" />
                      <span>{{item.LikeCount}}</span>
                    </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
              <div class="list" v-for="item in list" :key="item.Id" @click="toContentPage(item.Id, item.Type)">
                <div class="item_img">
                  <img class="item_cover" :src="item.Thumbnail" />
                  <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
                </div>
                <div class="item_content">
                  <span class="item_title">{{item.Title}}</span>
                  <p class="item_time">{{item.yyyymmdd}}</p>
                  <div class="item_nums">
                    <div class="nums">
                      <img src="@/assets/images/browse_comm_icon@2x.png" />
                      <span>{{item.WatchCount}}</span>
                    </div>
                    <div class="nums">
                      <img src="@/assets/images/like_comm_icon@2x.png" />
                      <span>{{item.LikeCount}}</span>
                    </div>
                  </div>
                </div>
              </div>
          </template>
        </waterfall>
      </div>
    </div>
    </mescroll-vue>
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: 'ExtracardiacPrefecture',
  data() {
    return {
      bannerList: [],
      zone: "xwzq_yts",
      codeList: [],
      activeCode: '',
      activeTCode: '',
      col: 1,
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			},
      poster: ''
    }
  },
  mixins: [mixin],
  components: {
    MescrollVue
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
    activeColumn() {
      return this.activeCode.split('_')[2];
    },
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  watch: {
    activeColumn(newVal) {
      if(newVal == 'dkjy') {
        this.col = 1;
      } else {
        this.col = 2;
      }
    },
    activeTCode(newVal) {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
      if(newVal == 'xinwaizq|xwzq_zgs|xwzq_zgs_lxlc|') {
        this.mescrollUp.empty = {};
      } else {
        this.mescrollUp.empty = {
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        };
      }
      // if(newVal != 'xinwaizq|xwzq_zgs|xwzq_zgs_lxlc|') {
      //   this.mescroll.resetUpScroll() // 刷新列表数据
      // } else {
      //   this.mescroll.endSuccess(this.list.length);
      //   this.mescroll.removeEmpty();
      // }
    }
  },
  created() {
    if(this.code) {
      this.zone = this.code;
    }
    this.getBanner();
    this.getCodeList(this.zone);
  },
  activated(){
    this.mescroll.setBounce(false);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  methods: {
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=' + this.zone).then(res => {
        this.bannerList = res.Data;
      })
    },
    getImage() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=' + this.activeCode).then(res => {
        if(res.Data.length > 0) {
          this.poster = res.Data[0].File_Url;
        }
      })
    },
    changeZone(val, name) {
      this.zone = val;
      this.getBanner();
      this.getCodeList(this.zone);
      this.BuriedPoint(1, name, val, '/extracardiacPrefecture');
    },
    getCodeList(pcode) {
      this.$axios.get('/Api/Api/Web/Article/GetCodeList?pcode=' + pcode).then(res => {
        this.codeList = res.Data;
        this.activeCode = res.Data[0].Code;
        this.activeTCode = res.Data[0].TCode;
      })
    },
    changeCode(code, tcode, name) {
      if(code == 'xwzq_zgs_ssjj') {
        this.$dialog.alert({
          title: '提示',
          message: '敬请期待',
        }).then(() => { });
      } else {
        this.activeCode = code;
        this.activeTCode = tcode;
        this.getImage();
        this.BuriedPoint(1, name, tcode, '/extracardiacPrefecture');
      }
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.activeTCode,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          code: this.code,
          id: Id,
          type: Type
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #F9F9F9;
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 0;
    height: auto;
    padding: 15px 0px 0px 0px;
    box-sizing: border-box;
  }
  .banner {
    position: relative;
    border-radius: 10px;
    margin: 0 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px #ebedf0;
    .bannerImg {
      display: block;
      width: 100%;
    }
    .goBack {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 30px;
      height: 30px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    margin-top: 42px;
    background-color: #FFFFFF;
    border-radius: 16px 16px 0 0;
    .title {
      position: absolute;
      top: -23px;
      left: 14%;
      width: 72%;
      img {
        display: block;
        width: 100%;
      }
      .Asia_Pacific {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 95%;
      }
      .China {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 95%;
      }
    }
    .classifyBox {
      display: flex;
      // justify-content: space-between;
      padding: 0 12px;
      box-sizing: border-box;
      margin-top: 35px;
      span {
        position: relative;
        color: #333333;
        font-size: 15px;
        line-height: 34px;
        margin-right: 30px;
      }
      .active {
        color: #CA001B;
      }
      .active::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #CA001B;
      }
    }
    .classifyFlexBox {
      display: flex;
      justify-content: space-between;
      padding: 0 12px;
      box-sizing: border-box;
      margin-top: 35px;
      span {
        position: relative;
        color: #333333;
        font-size: 15px;
        line-height: 34px;
        margin-right: 0px;
      }
      .active {
        color: #CA001B;
      }
      .active::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #CA001B;
      }
    }
    .workTitle {
      font-size: 15px;
      color: #CA001B;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .listBox {
      flex: 1;
      background-color: #F9F9F9;
      padding: 12px;
      box-sizing: border-box;
      border-radius: 16px 16px 0 0;
      .list {
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 10px;
        .item_img {
          position: relative;
          .item_cover {
            display: block;
            width: 100%;
          }
          .playIcon {
            position: absolute;
            right: 12px;
            bottom: 12px;
            width: 30px;
            height: 30px;
            filter: opacity(80%);
          }
        }
        .item_content {
          padding: 5px 8px 8px;
          .item_title {
            height: 38px;
            font-size: 14px;
            color: #333333;
            overflow: hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
            word-wrap: break-word;
          }
          .item_time {
            font-size: 12px;
            color: #999999;
            line-height: 24px;
          }
          .item_label {
            display: flex;
            flex-wrap: wrap;
            span {
              display: inline-block;
              height: 20px;
              line-height: 20px;
              border-radius: 2px;
              padding: 0 6px;
              margin-right: 6px;
              margin-bottom: 5px;
              background-color: rgba(255, 73, 98, 0.15);
              font-size: 11px;
              color: #CA001B;
            }
          }
          .item_nums {
            display: flex;
            justify-content: flex-end;
            .nums {
              display: flex;
              align-items: center;
              img {
                width: 12px;
                margin: 0 2px 0 6px;
              }
              span {
                font-size: 11px;
                color: #666666;
              }
            }
          }
        }
      }
      .list1 {
        display: flex;
        border-radius: 8px;
        .item_img1 {
          position: relative;
          width: 50%;
          border-radius: 8px;
          overflow: hidden;
          .item_cover1 {
            display: block;
            width: 100%;
          }
          .playIcon1 {
            position: absolute;
            right: 32px;
            bottom: 50%;
            transform: translateY(15px);
            width: 30px;
            height: 30px;
          }
        }
        .item_content1 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          padding: 8px;
          .item_title1 {
            font-size: 14px;
            color: #333333;
            overflow: hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
            word-wrap: break-word;
          }
        }
      }
      .lxlcContent {
        margin-top: 20px;
        .lxlcItem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          width: 80%;
          height: 50px;
          background-color: #FFFFFF;
          border-radius: 10px;
          padding: 0 35px;
          margin: 0 auto 20px;
          box-shadow: 0px 0px 0px 2px rgba(235,237,240, 0.4);
          color: #333333;
          font-size: 15px;
          img {
            width: 12px;
          }
        }
      }
    }
  }
  .poster {
    img {
      width: 100%;
    }
  }
}

/deep/ .swiper-pagination-bullet-active {
  background-color: #CA001B !important;
}

/deep/ .swiper-pagination-bullet {
  width: 10px;
  height: 3px;
  border-radius: 3px;
  background-color: #999999;
  margin: 0;
}

/deep/ .swiper-pagination-bullets {
  bottom: 0px;
}

/deep/ .van-swipe__indicators {
  top: 90%;
}
</style>